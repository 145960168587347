import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'

 class DirectionalComponent extends Component {
  render() {
    const {children, i18n} = this.props

    return <div style={{direction: i18n.language === 'ar' ? 'rtl' : 'ltr'}}>{children}</div>
  }
}
export default withTranslation("translation")(DirectionalComponent)