import React, { Component } from "react";

import Header from "./Header";
import Footer from "./Footer";
import ResizeDetector from "./ResizeDetector";
import DirectionalComponent from "./DirectionalComponent";

export default class Page extends Component {
    resize = () => {
        window.parent.postMessage(
            {
                frameHeight: document.querySelector(".page").offsetHeight,
            },
            "*"
        );
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    render() {
        const { headerComponents, footerComponents } = this.props;
        return (
            <div className="page">
                <DirectionalComponent>
                    <Header {...this.props} children={() => (headerComponents ? headerComponents() : null)} />
                    {this.props.children()}
                    <Footer {...this.props} children={() => (footerComponents ? footerComponents() : null)} />
                    <ResizeDetector onResize={this.resize} />
                </DirectionalComponent>
            </div>
        );
    }
}
