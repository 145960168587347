import Engine from '../models/Engine';
import Instruction from '../models/Instruction';
import Message from '../models/Message';
import Sector from '../models/Sector';
import Question from '../models/Question';
import Grid from '../models/Grid';

import SectorType from '../models/SectorType';
import InputType from '../models/InputType';
import AnswerType from '../models/AnswerType';

const subjectQuestions = [];
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What would you like your story to be about?',
        'story',
        InputType.circle,
        AnswerType.input,
        'Write your answer in the circle below'
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose 4 words to describe {ANSWER0}',
        '4 words',
        InputType.square,
        AnswerType.input,
        'Type in a word next to each side of the square in any order'
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What 2 words would other people use to describe the strength and weakness of the location of {ANSWER0}',
        'location',
        InputType.infinity,
        AnswerType.input
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What 2 words would other people use to describe the strength and weakness of the logo of {ANSWER0}',
        'logo',
        InputType.infinity,
        AnswerType.input
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What 2 words would other people use to describe the strength and weakness of the employees of {ANSWER0}',
        'employees',
        InputType.infinity,
        AnswerType.input
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What 2 words would other people use to describe the strength and weakness of the online presence of {ANSWER0}',
        'online',
        InputType.infinity,
        AnswerType.input
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose 4 Words to describe the sales process or business cycle of {ANSWER0} from start to finish',
        'sales process or business cycle',
        InputType.square,
        AnswerType.input,
        'Type in a word next to each side of the square. For example, the 4 parts of a sales cycle for a marketing company can be: introduction, negotiation, confirmation, replication'
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Identify the 3 golden values or most important principles of {ANSWER0}',
        '',
        InputType.triangle,
        AnswerType.input,
        'Type in a word next to each corner of the triangle (i.e. safety, charity, trust, etc.)'
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Identify the 3 main levels of employees or type of people involved in {ANSWER0}',
        '',
        InputType.triangle,
        AnswerType.input,
        'Type in a word next to each corner of the triangle (i.e. owner, chef, waiter)'
    )
);

subjectQuestions.push(new Question(Math.random().toString(36).substring(7), 'Identify the 2 most valuable audiences for {ANSWER0}', '', InputType.infinity, AnswerType.input));

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Who is the most recognizable person or the best narrator to speak about {ANSWER0}',
        '',
        InputType.circle,
        AnswerType.input
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'How would you describe the fashion or the clothes of {ANSWER25}, or what would be the best clothes for {ANSWER25} to wear in an interview',
        '',
        InputType.circle,
        AnswerType.input
    )
);

subjectQuestions.push(
    new Question(Math.random().toString(36).substring(7), 'What is the most important milestone in the history of {ANSWER0}', '', InputType.circle, AnswerType.input)
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What kind of music do you want people to hear when they watch a video about {ANSWER0}',
        '',
        InputType.circle,
        AnswerType.input
    )
);

// 14 questions
const subjectQuestionsSector = new Sector(Math.random().toString(36).substring(7), '', '', SectorType.steps, subjectQuestions);

const breatherSector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.message,
    new Message(Math.random().toString(36).substring(7), 'Congratulations! You’re 1 step closer to making a video about {ANSWER0}', 'Take a breather.', 'Continue')
);

const selectionQuestions = [];
selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite word',
        '',
        InputType.triangle,
        AnswerType.selection,
        'Click on one of the corners of the triangle',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER17}', '{ANSWER18}', '{ANSWER19}']
    )
);
selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Eliminate 1 word that does not belong',
        '',
        InputType.square,
        AnswerType.selection,
        'Click on one of the sides of the square to get rid of a word',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER1}', '{ANSWER2}', '{ANSWER3}', '{ANSWER4}']
    )
);
selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite 3 words',
        '',
        InputType.octagram,
        AnswerType.selection,
        'Click on 3 corners of the shape to make your decision',
        '',
        'Previous',
        'Next',
        [undefined, undefined, undefined],
        ['{ANSWER5}', '{ANSWER6}', '{ANSWER7}', '{ANSWER8}', '{ANSWER9}', '{ANSWER10}', '{ANSWER11}', '{ANSWER12}']
    )
);
selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Now it is time to select 1 favorite word',
        '',
        InputType.infinity,
        AnswerType.selection,
        'Click on either side of the symbol to select a word',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER23}', '{ANSWER24}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose the best word',
        '',
        InputType.triangle,
        AnswerType.selection,
        'Click on one of the corners of the triangle',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANTIMATTER15[0]}', '{ANTIMATTER15[1]}', '{ANTIMATTER15[2]}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Now is the time to select 1 favorite word',
        '',
        InputType.square,
        AnswerType.selection,
        'Click on one side of the square to keep a word',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER13}', '{ANSWER14}', '{ANSWER15}', '{ANSWER16}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Select your favorite word',
        '',
        InputType.pentagram,
        AnswerType.selection,
        'Click on one of the corners of the shape to select a word',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER31}', '{ANSWER32}', '{ANSWER33}', '{ANSWER26}', '{ANSWER28}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Select your favorite word',
        '',
        InputType.hexagram,
        AnswerType.selection,
        'Click on one of the corners of the shape to select a word',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER20}', '{ANSWER21}', '{ANSWER22}', '{ANSWER23}', '{ANSWER24}', '{ANSWER25}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose 3 words that you would like to keep',
        '',
        InputType.hexagram,
        AnswerType.selection,
        'Click on three corners of the shape to select your words',
        '',
        'Previous',
        'Next',
        [undefined, undefined, undefined],
        ['{ANSWER30}', '{ANTIMATTER16[0]}', '{ANTIMATTER16[1]}', '{ANTIMATTER16[2]}', '{ANTIMATTER16[3]}', '{ANTIMATTER16[4]}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        "What is a word that you have not used yet in this process, but you'd still like to include it because you like it so much",
        '',
        InputType.circle,
        AnswerType.input,
        'Write a word in the circle below, and it can be anything'
    )
);

const selectionQuestionsSector = new Sector(Math.random().toString(36).substring(7), '', '', SectorType.steps, selectionQuestions);

const halfwaySector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.message,
    new Message(
        Math.random().toString(36).substring(7),
        'Congratulations! Your story about {ANSWER0} is halfway there!',
        'Take another breather. Click next to continue.',
        'Continue'
    )
);

const gridInstructionSector = new Sector(
    Math.random().toString(36).substring(7),
    'instruction',
    'grid instruction',
    SectorType.instruction,
    new Instruction(
        0,
        'Now we’re going to play an intuition game',
        '1. The first ideas that come into your mind are the ones that we want. <br> 2. There is never a right or a wrong answer. <br> 3. Don’t use the same answer twice. <br> 4. It’s easier if you pretend that your eyes are a camera and your brain is an image library'
    )
);

const gridSector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.grid,
    new Grid(
        Math.random().toString(36).substring(7),
        'Image grid',
        'Click on any number in the grid below. Once the grid is complete, you will be able to proceed to the final section.',
        [
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER42} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER35} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER36} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER37} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER42} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER35} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER36} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER37} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} process',
                    'process',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER42} process',
                    'process',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER35} process',
                    'process',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER36} process',
                    'process',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER37} process',
                    'process',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER42} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER35} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER36} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER37} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER42} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER35} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER36} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER37} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
        ]
    )
);

const gridSelectionQuestions = [];
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for {ANSWER0} focus',
        'focus',
        InputType.pentagram,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER43}', '{ANSWER44}', '{ANSWER45}', '{ANSWER46}', '{ANSWER47}']
    )
);
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for {ANSWER0} people',
        'people',
        InputType.pentagram,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER48}', '{ANSWER49}', '{ANSWER50}', '{ANSWER51}', '{ANSWER52}']
    )
);
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for {ANSWER0} process',
        'process',
        InputType.pentagram,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER53}', '{ANSWER54}', '{ANSWER55}', '{ANSWER56}', '{ANSWER57}']
    )
);
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for {ANSWER0} senses',
        'senses',
        InputType.pentagram,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER58}', '{ANSWER59}', '{ANSWER60}', '{ANSWER61}', '{ANSWER62}']
    )
);
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for {ANSWER0} setting',
        'setting',
        InputType.pentagram,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER63}', '{ANSWER64}', '{ANSWER65}', '{ANSWER66}', '{ANSWER67}']
    )
);
const gridSelectionQuestionsSector = new Sector(Math.random().toString(36).substring(7), '', '', SectorType.steps, gridSelectionQuestions);

const synopsis = `
<p>The main theme of a video about {ANSWER0} is {ANSWER29} and can be represented by images of {ANSWER68}. </p>
<p>Your primary audience is {ANSWER23}, who will enjoy a story that takes place in a location that is {ANSWER37}.</p>
<p>Start your video with music that sounds like {ANSWER28} and put your story into context with images of {ANSWER72}. Or, start with an interview, and then add images of {ANSWER72}.</p>

<p>The first choice for a narrator for a story about {ANSWER0} is {ANSWER25}, who needs to speak in a voice that sounds {ANSWER39}, {ANSWER40} and {ANSWER41}, but does NOT sound {ANTIMATTER22[0]}, {ANTIMATTER22[1]} and {ANTIMATTER22[2]}.</p> 
<p>The best place for an on camera interview with {ANSWER25} is in a place where you will find {ANSWER29}. Chose clothes that are {ANSWER26} for the interview. </p>

<p>It’s always a good idea to describe the process of {ANSWER36} and remind the audience about {ANSWER27} which can be represented with an image of {ANSWER70}. Use an image of {ANSWER71} to show {ANSWER38} in the {ANSWER36} process. </p>
<p>If you want to compose a voiceover for a story about {ANSWER0}, remember that you can comfortably say 125 words in a minute, at most 150 words.</p>
<p>Remember to emphasize these words in a voiceover about {ANSWER0}: 
{ANSWER29}, {ANSWER42}, {ANSWER35}, {ANSWER36}, and {ANSWER37}.</p>
`;

const synopsisSector = new Sector(Math.random().toString(36).substring(7), 'One minute video about {ANSWER0}', '', SectorType.synopsis, synopsis);

const sectors = [subjectQuestionsSector, breatherSector, selectionQuestionsSector, halfwaySector, gridInstructionSector, gridSector, gridSelectionQuestionsSector, synopsisSector];
const engine = new Engine('deluxe', 'Deluxe', sectors);

export default engine;
