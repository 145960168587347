import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Step from "../Step";

import { flatten } from "../../utils/arrayUtils";

class GridSector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeQuestion: null,
        };
    }
    render() {
        // <UnsplashImage src={`https://api.unsplash.com/photos/random?client_id=HDMW2aAedDqzXRjH3BiXAnclmA_1GrpE2qaOEFIBV-s&query=${item.answer[0]}`} />

        const { t, grid, engine, answers, questions, nextSector, changeQuestion } = this.props;
        const gridAnswers = flatten(grid.items.map((row) => row.map((item) => item.answer[0])));
        const hasFilledAll = !gridAnswers.includes(undefined) && !gridAnswers.includes(null);
        let count = 0;
        return (
            <main>
                <header></header>
                {this.state.activeQuestion != null ? (
                    <Step
                        engine={engine}
                        primaryColor={engine.primaryColor}
                        accentColor={engine.accentColor}
                        question={this.state.activeQuestion}
                        questions={questions}
                        answers={answers}
                        activeStep={this.state.step}
                        onAnswer={(e, i) => {
                            const newQuestion = { ...this.state.activeQuestion };
                            newQuestion.answer[i] = e;

                            changeQuestion(newQuestion);
                        }}
                        onNext={(_) => {
                            this.setState({ activeQuestion: null });
                        }}
                    />
                ) : (
                    <div>
                        <h1>{t(grid.title)}</h1>
                        <p style={{ fontStyle: "italic" }}>{t(grid.instruction)}</p>
                        <div className="grid">
                            {grid.items.map((row, rowIndex) => (
                                <div className="grid-row" key={rowIndex}>
                                    {row.map((item) => {
                                        count++;
                                        return (
                                            <div
                                                key={item.id}
                                                className=""
                                                onClick={() => {
                                                    this.setState({ activeQuestion: item });
                                                }}
                                            >
                                                {item.answer[0] ? "✓" : count}
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                        <div className="buttons">
                            <div></div>
                            <div>
                                <button
                                    type="button"
                                    className="next"
                                    disabled={!hasFilledAll}
                                    style={{ backgroundColor: engine.accentColor }}
                                    onClick={(e) => {
                                        nextSector();
                                    }}
                                >
                                    {t(grid.nextButtonText)}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        );
    }
}
export default withTranslation("translation")(GridSector);
