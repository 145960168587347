import Engine from '../models/Engine';
import Sector from '../models/Sector';
import Question from '../models/Question';

import SectorType from '../models/SectorType';
import InputType from '../models/InputType';

const questions = [];
questions.push(new Question(Math.random().toString(36).substring(7), 'What would you like your 1-minute video to be about?', '1-minute video', InputType.circle));

questions.push(new Question(Math.random().toString(36).substring(7), 'Who is the target audience for a 1-minute video about {ANSWER0}', 'target audience', InputType.circle));

questions.push(
    new Question(Math.random().toString(36).substring(7), 'What kind of music would you like {ANSWER1} to hear in a 1-minute video about {ANSWER0}?', 'music', InputType.circle)
);

questions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Who is the best person for an interview or voiceover for a 1-minute video about {ANSWER0}?',
        'interview or voiceover',
        InputType.circle
    )
);

questions.push(
    new Question(Math.random().toString(36).substring(7), 'What kind of clothes should {ANSWER3} wear in a 1-minute video about {ANSWER0}?', 'clothes', InputType.circle)
);

questions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Identify three images that would make a 1-minute video about {ANSWER0} look really good?',
        'three images',
        InputType.triangle
    )
);
const questionsSector = new Sector(Math.random().toString(36).substring(7), '', '', SectorType.steps, questions);

const synopsis =
    'The best narrator for a 1-minute video about {ANSWER0} is {ANSWER3}. You should use a soundtrack that sounds like {ANSWER2} and ask {ANSWER3} to wear clothes that are {ANSWER4}. Your target audience is {ANSWER1} and the best images for a 1-minute video about {ANSWER0} are {ANSWER5}, {ANSWER6} and {ANSWER7}.';
const synopsisSector = new Sector(Math.random().toString(36).substring(7), 'One minute video about {ANSWER0}', '', SectorType.synopsis, synopsis);

const sectors = [questionsSector, synopsisSector];
const engine = new Engine('lite', 'lite', sectors);

export default engine;
