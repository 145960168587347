/**
    An Engine instance is the base datamodel for the SYSIAM question form and answers
 */
export default class Engine {

    id; 
    name;
    instruction;

    sectors;

    primaryColor;
    accentColor;

    language;

    constructor(id = 0, name = "", sectors = [], primaryColor = "#000000", accentColor = "#ce3234", language = "en") {
        
        this.id = id;
        this.name = name;

        this.sectors = sectors;

        this.primaryColor = primaryColor;
        this.accentColor = accentColor;

        this.language = language;
    }
}