import React from 'react';

import ShapeComponent from './ShapeComponent';

export default class Infinity extends ShapeComponent {
    render() {
        return (
            <div className="flex-container infinity">
                <div className="one">{this._renderInput(0)}</div>
                <div className="one">{this._renderInput(1)}</div>
                <div className="absolute-center">
                    <div className="shape infinity"></div>
                </div>
            </div>
        );
    }
}
