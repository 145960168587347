import React from 'react';

import ShapeComponent from './ShapeComponent';

export default class Pentagram extends ShapeComponent {
    render() {
        return (
            <div className="pentagram">
                {[0, 1, 2, 3, 4].map((index) => this._renderInput(index))}
                <div className="absolute-center center-shape">
                    <div className="shape pentagram"></div>
                </div>
            </div>
        );
    }
}
