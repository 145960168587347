import React, { Component } from 'react';

export default class Button extends Component {
    state = {
        hovered: false,
    };

    toggleHover() {
        this.setState({ hovered: !this.state.hovered });
    }

    render() {
        const { style, hoveredStyle, children, ...props } = this.props;

        return (
            <button {...props} onMouseEnter={(e) => this.toggleHover(e)} onMouseLeave={(e) => this.toggleHover(e)} style={this.state.hovered ? hoveredStyle || style || {} : style || {}}>
                {children}
            </button>
        );
    }
}
