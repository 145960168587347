import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { insertAnswers } from "./../utils/stringUtils";

/**
    The Synopsis generated based on the answers and configured Synopsis string

    @Props answers: array of answers
    @Props synopsis: string of synopsis definition
 */
class Synopsis extends Component {
    createTitle(title) {
        const { engine, questions, answers } = this.props;
        return insertAnswers(engine, questions, answers, title);
    }

    createSynopsis(synopsisTemplate) {
        const { engine, questions, answers } = this.props;
        return insertAnswers(engine, questions, answers, synopsisTemplate);
    }

    getAnswer(index) {
        const { answers } = this.props;
        return (answers[index] || "___").toLowerCase();
    }

    render() {
        const { t, engine, sector, synopsis, primaryColor } = this.props;

        console.log(t(`${engine.id}.synopsis`));
        return (
            <div className="synopsis">
                <h1
                    style={{ color: primaryColor }}
                    dangerouslySetInnerHTML={{ __html: this.createTitle(t(sector.name)) }}
                ></h1>
                <p
                    dangerouslySetInnerHTML={{
                        __html: this.createSynopsis(t(`${engine.id}.synopsis`) || synopsis || ""),
                    }}
                ></p>
            </div>
        );
    }
}
export default withTranslation("translation")(Synopsis);
