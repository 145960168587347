
export const insertAnswers = (engine, questions, answers, valueString, withStyling = true) => {
    const answerInsertions = [...valueString.matchAll(/{ANSWER\+?\d+}/g)] || [];
    const antiMatterInsertions = [...valueString.matchAll(/{ANTIMATTER\+?\d+\[\+?\d+]}/g)] || []

    answerInsertions.forEach((answerInsertion) => {
        const index = answerInsertion[0].match(/\d+/)[0];
        if (withStyling) {
            valueString = valueString.replace(answerInsertion[0], `<span class='focus' style='color: ${engine.accentColor}'>${(answers[index] || "___").toLowerCase() }</span>`)
        } else {
            valueString = valueString.replace(answerInsertion[0], `${(answers[index] || "___").toLowerCase() }`);
        }
    });

    antiMatterInsertions.forEach((antiMatterInsertion) => {
        const antiMatterAnswerIndex = antiMatterInsertion[0].match(/\[\d+\]/)[0];
        const questionIndex = parseInt(antiMatterInsertion[0].replace(antiMatterAnswerIndex, "").match(/\d+/)[0]);

        const question = questions[questionIndex];
        if (question) {
            const values = question.value.map((value, valueIndex) => insertAnswers(engine, questions, answers, question.value.length > 0 && (question.value[valueIndex] || ''), false));
            const antimatterAnswers = values.filter(value => !question.answer.includes(value));
            if (withStyling) {
                valueString  = valueString.replace(antiMatterInsertion[0], `<span class='focus' style='color: ${engine.accentColor}'>${(antimatterAnswers[antiMatterAnswerIndex.match(/\d+/)[0]] || "___").toLowerCase()}</span>`);
            } else {
                valueString = valueString.replace(antiMatterInsertion[0], `${(antimatterAnswers[antiMatterAnswerIndex.match(/\d+/)[0]] || "___").toLowerCase()}`);
            }
        } else {
            valueString  = valueString.replace(antiMatterInsertion[0], `<span class='focus' style='color: ${engine.accentColor}'>___</span>`);
        }

    })

    return valueString;
}