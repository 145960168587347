import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { insertAnswers } from './../utils/stringUtils';

import InputType from '../models/InputType';
import Circle from './ui/shapes/Circle';
import Infinity from './ui/shapes/Infinity';
import Triangle from './ui/shapes/Triangle';
import Square from './ui/shapes/Square';
import Pentagram from './ui/shapes/Pentagram';
import Hexagram from './ui/shapes/Hexagram';
import Heptagram from './ui/shapes/Heptagram';
import Octagram from './ui/shapes/Octagram';
import Button from './ui/Button';

/**
    A step which 
 */
class Step extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    createQuestionTitle(question) {
        const { t, accentColor, engine, questions, answers } = this.props;
        const titleString = t(question.question).replace(`/${question.focus}/g`, `<span class='focus' style='color: ${accentColor}'>${t(question.focus)}</span>`);
        return insertAnswers(engine, questions, answers, titleString);
    }

    validateQuestionValue(question) {
        const { t } = this.props;
        const allAnswersFilled = !question.answer.includes(undefined) && !question.answer.includes(null);
        if (!allAnswersFilled) {
            this.setState({
                error: t('haveToFillInAnswers'),
            });
            return false;
        }
        return true;
    }

    componentDidMount() {
        const inputs = document.querySelectorAll('.step input');
        if (inputs.length > 0) inputs[0].focus();
    }

    render() {
        const {
            t, 
            activeStep,
            index,
            question,
            questions,
            engine,
            onAnswer,
            answers,
            primaryColor,
            accentColor,
            previousActive,
            onNext,
            onPrevious,
            nextActive,
        } = this.props;

        if (activeStep !== index) return null;

        const allAnswersFilled = !question.answer.includes(undefined) && !question.answer.includes(null);
        let inputType = (
            <Circle
                value={question.answer[0]}
                engine={engine}
                className="rectangle"
                onChange={(e) => {
                    this.setState({ error: '' });
                    onAnswer(e, 0);
                }}
            />
        );

        switch (question.inputType) {
            case InputType.infinity:
                inputType = (
                    <Infinity
                        onAnswer={(e, index) => {
                            this.setState({ error: '' });
                            onAnswer(e, index);
                        }}
                        engine={engine}
                        answers={answers}
                        questions={questions}
                        question={question}
                    />
                );
                break;
            case InputType.triangle:
                inputType = (
                    <Triangle
                        onAnswer={(e, index) => {
                            this.setState({ error: '' });
                            onAnswer(e, index);
                        }}
                        engine={engine}
                        answers={answers}
                        questions={questions}
                        question={question}
                    />
                );
                break;
            case InputType.square:
                inputType = (
                    <Square
                        onAnswer={(e, index) => {
                            this.setState({ error: '' });
                            onAnswer(e, index);
                        }}
                        engine={engine}
                        answers={answers}
                        questions={questions}
                        question={question}
                    />
                );
                break;
            case InputType.pentagram:
                inputType = (
                    <Pentagram
                        onAnswer={(e, index) => {
                            this.setState({ error: '' });
                            onAnswer(e, index);
                        }}
                        engine={engine}
                        answers={answers}
                        questions={questions}
                        question={question}
                    />
                );
                break;
            case InputType.hexagram:
                inputType = (
                    <Hexagram
                        onAnswer={(e, index) => {
                            this.setState({ error: '' });
                            onAnswer(e, index);
                        }}
                        engine={engine}
                        answers={answers}
                        questions={questions}
                        question={question}
                    />
                );
                break;
            case InputType.heptagram:
                inputType = (
                    <Heptagram
                        onAnswer={(e, index) => {
                            this.setState({ error: '' });
                            onAnswer(e, index);
                        }}
                        engine={engine}
                        answers={answers}
                        questions={questions}
                        question={question}
                    />
                );
                break;
            case InputType.octagram:
                inputType = (
                    <Octagram
                        onAnswer={(e, index) => {
                            this.setState({ error: '' });
                            onAnswer(e, index);
                        }}
                        engine={engine}
                        answers={answers}
                        questions={questions}
                        question={question}
                    />
                );
                break;
            default:
                inputType = (
                    <Circle
                        value={question.answer[0]}
                        className="rectangle"
                        onChange={(e) => {
                            this.setState({ error: '' });
                            onAnswer(e, 0);
                        }}
                    />
                );
                break;
        }

        return (
            <div className="step">
                {activeStep === index && (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.validateQuestionValue(question)) {
                                onNext(e);
                            }
                        }}
                    >
                        <h1 className="question" style={{ color: primaryColor }} dangerouslySetInnerHTML={{ __html: this.createQuestionTitle(question) }} />
                        <div className="answer">
                            <p style={{ fontStyle: 'italic' }}>{t(question.instruction)}</p>
                            {inputType}
                        </div>
                        {this.state.error && <div className="error-message">{this.state.error}</div>}
                        <div className="buttons">
                            <div>
                                {(activeStep && (previousActive || true)) > 0 && (
                                    <Button
                                        type="button"
                                        className="previous"
                                        style={{ border: `2px solid ${accentColor}`, background: `transparent`, color: `${accentColor}` }}
                                        hoveredStyle={{ background: `${accentColor}`, border: `2px solid transparent` }}
                                        onClick={onPrevious}
                                    >
                                        {t(question.previousButtonText)}
                                    </Button>
                                )}
                            </div>
                            <div>
                                {(nextActive || true) && (
                                    <button
                                        disabled={!allAnswersFilled}
                                        className="next"
                                        style={{ backgroundColor: accentColor }}
                                        onClick={(e) => {
                                            if (this.validateQuestionValue(question)) {
                                                onNext(e);
                                            }
                                        }}
                                    >
                                        {t(question.nextButtonText)}
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        );
    }
}

export default withTranslation('translation')(Step);
