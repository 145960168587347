import React, { Component } from 'react';

import { getQueryParams } from '../utils/urlUtils';

export default class Footer extends Component {
    render() {
        // hide footer if given as a parameter
        const queryParams = getQueryParams(window.location.search);
        if (queryParams.hideFooter && queryParams.hideFooter === 'true') {
            return null;
        }

        return <footer className="center">2020 &copy; All Rights Reserved. One Minute Media Company, Inc.</footer>;
    }
}
