/**
    Data model for the Grid sector
 */
export default class Grid {

    id;

    title;
    instruction;
    items;
    
    nextButtonText;

    constructor(id, title, instruction, items, nextButtonText = 'Next') {
        this.id = id;

        this.title = title;
        this.instruction = instruction;
        this.items = items;
        this.nextButtonText = nextButtonText;
    }
}