import InputType from './InputType';
import AnswerType from './AnswerType';

/**
    The question that is asked itself, this can consist of different types and thus answers;
 */
export default class Question {

    id;
    order;

    question;
    focus;

    inputType;
    answerType;
    answer;
    value;

    instruction; 
    description;

    previousButtonText;
    nextButtonText;

    constructor(
        id,
        question = "", 
        focus = "", 
        inputType = InputType.one, 
        answerType = AnswerType.input,
        instruction = 'Type your answer below', 
        description = '', 
        previousButtonText = 'Previous', 
        nextButtonText = 'Next', 
        answer = null, 
        value = []
    ) {

        this.id = id;
        this.question = question;
        this.focus = focus;
        this.inputType = inputType;
        this.answerType = answerType;
        
        if (!answer) {
            this.answer = [];
            for(var i = 0; i < (inputType); i++) {
                this.answer.push(undefined);
            }
        } else {
            this.answer = answer;
        }

        this.value = value;
        
        this.instruction = instruction;
        this.description = description;

        this.previousButtonText = previousButtonText;
        this.nextButtonText = nextButtonText;
    }
}