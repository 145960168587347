import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { insertAnswers } from '../../utils/stringUtils';

class InstructionSector extends Component {

    parseContent(content){
        const { t, engine, questions, answers } = this.props;
        return insertAnswers(engine, questions, answers, t(content));
    }

    render() {
        const { t, instruction, engine, nextSector } = this.props;
        return (
            <div>
                <h1 dangerouslySetInnerHTML={{ __html: this.parseContent(instruction.title)}}></h1>
                <div dangerouslySetInnerHTML={{ __html: this.parseContent(instruction.content)}}></div>
                <div className="buttons">
                    <div></div>
                    <div>
                        <button
                            type="button"
                            className="next"
                            style={{ backgroundColor: engine.accentColor }}
                            onClick={() => {
                                nextSector()
                            }}
                        >
                            {t("next")}
                        </button>
                    </div>
                </div>
            </div>

        );
    }
}

export default withTranslation("translation")(InstructionSector)