import React, { Component } from 'react';

import { insertAnswers } from '../../../utils/stringUtils';

import AnswerType from '../../../models/AnswerType';

import SingleInput from './../SingleInput';
import SingleSelection from './../SingleSelection';

export default class ShapeComoponent extends Component {
    createValue(index, question) {
        const { engine, questions, answers } = this.props;
        let valueString = question.value.length > 0 && (question.value[index] || '');
        return insertAnswers(engine, questions, answers, valueString, false);
    }

    //TODO: deselect non correct answers
    checkSelectableAnswerValues() {}

    _renderInput(index) {
        const { question, className, onAnswer } = this.props;
        if (question.answerType === AnswerType.selection) {
            const valueString = this.createValue(index, question);
            const hasFilledAll = !question.answer.includes(undefined) && !question.answer.includes(null);
            const hasAnswer = question.answer.includes(valueString);

            return (
                <SingleSelection
                    key={index}
                    className={className}
                    checked={question.answer.includes(valueString)}
                    subject={valueString}
                    disabled={!hasAnswer && hasFilledAll}
                    valueKey={question.value[index]}
                    onChange={(e) => {
                        if (!e.target.checked) {
                            const filledIndex = question.answer.findIndex((answer) => answer === valueString);
                            if (filledIndex >= 0) onAnswer(null, filledIndex);
                        } else {
                            const openIndex = question.answer.findIndex((answer) => answer === undefined || answer === null);
                            if (openIndex >= 0) onAnswer(valueString, openIndex);
                        }
                    }}
                />
            );
        }

        return <SingleInput key={index} className={className} value={question.answer[index] || ''} onChange={(e) => onAnswer(e.target.value, index)} />;
    }
}
