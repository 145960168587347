import React, { Component } from 'react';

import Synopsis from './../Synopsis';

export default class SynopsisSector extends Component {
    render() {
        const { engine, answers, questions, synopsis } = this.props;
        return <Synopsis engine={engine} answers={answers} questions={questions} synopsis={synopsis} {...this.props} />;
    }
}
