import React, { Component } from 'react';

/**
    A simple, single input.

    @Props style: object ->  custom styling of the component
    @Props className: string -> custom className for component
    @Props value: any -> the value the input should contain
    @Props onChange: function -> the function that handles when the value changes
 */
export default class SingleInput extends Component {
    render() {
        const { style, className, value, onChange } = this.props;
        return <input type="text" style={style} className={className} value={value || ''} onChange={onChange} />;
    }
}
