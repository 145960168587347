import React from 'react';

import ShapeComponent from './ShapeComponent';

export default class Triangle extends ShapeComponent {
    render() {
        return (
            <div className="triangle">
                {[0, 1, 2].map((index) => this._renderInput(index))}
                <div className="absolute-center center-shape">
                    <div className="shape triangle"></div>
                </div>
            </div>
        );
    }
}
