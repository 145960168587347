import React, { Component } from 'react';

import SectorType from '../models/SectorType';

import StepperSector from './sectors/StepperSector';
import InstructionSector from './sectors/InstructionSector';
import SynopsisSector from './sectors/SynopsisSector';
import MessageSector from './sectors/MessageSector';
import GridSector from './sectors/GridSector';

import { flatten } from '../utils/arrayUtils';

/**
    The sector is a functional component that defines the sort of 
 */
export default class Sector extends Component {
    render() {
        const { engine, sector, changeSector } = this.props;

        const questions = flatten(
            engine.sectors.map((sector) => {
                if (sector.data.items) {
                    return sector.data.items;
                } else if (Array.isArray(sector.data)) {
                    return sector.data;
                }
                return [];
            })
        );

        const answers = flatten(questions.map((question) => question.answer));

        let activeSector = null;

        switch (sector.type) {
            case SectorType.instruction:
                activeSector = <InstructionSector instruction={sector.data} answers={answers} questions={questions} {...this.props} />;
                break;
            case SectorType.message:
                activeSector = <MessageSector message={sector.data} answers={answers} questions={questions} {...this.props} />;
                break;
            case SectorType.steps:
                activeSector = (
                    <StepperSector
                        questions={sector.data}
                        answers={answers}
                        allQuestions={questions}
                        changeQuestion={(newQuestion) => {
                            const newSector = { ...sector };
                            const questionIndex = newSector.data.findIndex((el) => el.id === newQuestion.id);
                            newSector.data[questionIndex] = newQuestion;

                            changeSector(newSector);
                        }}
                        {...this.props}
                    />
                );

                break;
            case SectorType.grid:
                activeSector = (
                    <GridSector
                        grid={sector.data}
                        answers={answers}
                        questions={questions}
                        changeQuestion={(newQuestion) => {
                            const newSector = { ...sector };
                            let questionYIndex = 0;
                            let questionXIndex = 0;
                            newSector.data.items.forEach((y, yIndex) => {
                                y.forEach((x, xIndex) => {
                                    if (x.id === newQuestion.id) {
                                        questionYIndex = yIndex;
                                        questionXIndex = xIndex;
                                    }
                                });
                            });

                            newSector.data.items[questionYIndex][questionXIndex] = newQuestion;

                            changeSector(newSector);
                        }}
                        {...this.props}
                    />
                );
                break;
            case SectorType.synopsis:
                activeSector = <SynopsisSector answers={answers} questions={questions} synopsis={sector.data} {...this.props} />;
                break;
            default:
                activeSector = <MessageSector message={sector.data} answers={answers} questions={questions} {...this.props} />;
                break;
        }

        return activeSector;
    }
}
