import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './resources/languages/en.json';
import vi from './resources/languages/vi.json';
import km from './resources/languages/km.json';
import ar from './resources/languages/ar.json';
import th from './resources/languages/th.json';
import my from './resources/languages/my.json';
import lo from './resources/languages/lo.json';
import ma from './resources/languages/ma.json';
import uk from './resources/languages/uk.json';
import es from './resources/languages/es.json';
import ru from './resources/languages/ru.json';
import nl from './resources/languages/nl.json';
import pt from './resources/languages/pt.json';
import fr from './resources/languages/fr.json';
import gr from './resources/languages/gr.json';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: { en, vi, km, th, ar, my, lo, ma, uk, es, ru, nl, pt, fr, gr },
        lng: 'en',
        fallbackLng: ['en', 'vi', 'km', 'th', 'ar', 'my', 'lo', 'ma', 'uk', 'es', 'ru', 'nl', 'pt'],
        keySeparator: false,

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
