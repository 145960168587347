/**
 The types of answer types that are available and configurable. 
 */
const AnswerTypeObject = {
    "input":            1,     
    "selection":        2,
};

const AnswerType = Object.freeze(AnswerTypeObject);

export default AnswerType;