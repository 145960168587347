/**

 */
const SectorTypeObject = {
    "instruction": 0,
    "steps": 1, 
    "message": 2, 
    "grid": 3,
    "synopsis": 4
}

const SectorType = Object.freeze(SectorTypeObject);

export default SectorType;