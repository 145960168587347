import React from 'react';

import ShapeComponent from './ShapeComponent';

export default class Octagram extends ShapeComponent {
    render() {
        return (
            <div className="octagram">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => this._renderInput(index))}
                <div className="absolute-center center-shape">
                    <div className="shape octagram"></div>
                </div>
            </div>
        );
    }
}
