import React, { Component } from 'react';

import StepIcon from './ui/StepIcon';

/**
    Steps Component shows the current steps
 */
export default class Steps extends Component {
    render() {
        const { step, steps, engine, onStepChange } = this.props;
        return (
            <div>
                {step < steps.length && (
                    <header>
                        {steps.map((_, index) => (
                            <StepIcon
                                key={index}
                                index={index}
                                primaryColor={engine.primaryColor}
                                accentColor={engine.accentColor}
                                step={index + 1}
                                activeStep={step}
                                onClick={(_) => onStepChange(index)}
                            />
                        ))}
                    </header>
                )}
            </div>
        );
    }
}
