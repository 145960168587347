/**
 The types of inputs that are available and configurable. 
 */
const InputTypeObject = {
    "circle":       1,      //See -> shapes/Circle
    "infinity":     2,      //See -> shapes/Infinity
    "triangle":     3,      //See -> shapes/Triangle,
    "square":       4,      //See -> shapes/Square
    "pentagram":    5,      //See -> shapes/Pentagram
    "hexagram":     6,      //See -> shapes/Hexagram
    "heptagram":    7,      //See -> shapes/heptagram
    "octagram":     8       //See -> shapes/Octagram
};

const InputType = Object.freeze(InputTypeObject);
export default InputType;