import React, { Component } from 'react';

export default class Header extends Component {
    render() {
        const { children } = this.props;
        return (
            <nav>
                <div className="container flex-container">
                    <div></div>
                    <div className="flex-container">{children()}</div>
                </div>
            </nav>
        );
    }
}
