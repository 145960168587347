import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import SelectionPage from "./pages/SelectionPage";

import "./index.css";

ReactDOM.render(<SelectionPage />, document.getElementById("root"));

serviceWorker.unregister();
