import SectorType from './SectorType';

export default class Sector {

    id;

    name;
    description;

    type;
    data;

    constructor(id = 0, name = "", description = "", type = SectorType.steps, data = []) {
        this.id = id;

        this.name = name;
        this.description = description;

        this.type = type;
        this.data = data;
    }
}