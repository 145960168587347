import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Page from "../components/Page";

import EnginePage from "./EnginePage";

import deluxe from "../configs/deluxe.js";
import medium from "../configs/medium.js";
import lite from "../configs/lite.js";

import i18n from "../i18n";

class SelectionPage extends Component {
    state = {
        selectedEngine: null,
    };

    componentDidMount() {
        setTimeout(() => {
            const searchQuery = new URLSearchParams(window.location.search);
            if (searchQuery.has("lang")) {
                i18n.changeLanguage(searchQuery.get("lang"));
            }
        }, 10);
    }

    render() {
        const { t } = this.props;
        const { selectedEngine } = this.state;

        if (selectedEngine) {
            return <EnginePage engine={selectedEngine} />;
        }
        return (
            <Page
                {...this.props}
                children={() => (
                    <div className="container">
                        <div className="engine-cards">
                            <div className="engine-card">
                                <h1>{t("liteVersion")}</h1>
                                <h2>{t("minutes", { count: 3 })}</h2>

                                <button onClick={() => this.setState({ selectedEngine: lite })}>
                                    {t("start")}
                                </button>
                            </div>
                            <div className="engine-card">
                                <h1>{t("mediumVersion")}</h1>
                                <h2>{t("minutes", { count: 15 })}</h2>

                                <button onClick={() => this.setState({ selectedEngine: medium })}>
                                    {t("start")}
                                </button>
                            </div>
                            <div className="engine-card">
                                <h1>{t("deluxeVersion")}</h1>
                                <h2>{t("minutes", { count: 30 })}</h2>

                                <button onClick={() => this.setState({ selectedEngine: deluxe })}>
                                    {t("start")}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            />
        );
    }
}

export default withTranslation("translation")(SelectionPage);
