import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
/**
    A simple, single input.

    @Props style: object ->  custom styling of the component
    @Props className: string -> custom className for component
    @Props value: any -> the value the input should contain
    @Props onChange: function -> the function that handles when the value changes
 */
class SingleSelection extends Component {
    render() {
        const { t, disabled, style, className, checked, onChange, valueKey, subject } = this.props;
        return (
            <div className={`selection ${disabled && 'disabled'}`}>
                <div className="absolute-center">
                    <input
                        type="checkbox"
                        style={style}
                        className={className}
                        checked={checked || false}
                        onChange={onChange}
                        disabled={disabled || false}
                        data-value-key={valueKey}
                    />
                    <label dangerouslySetInnerHTML={{ __html: t(subject) || '' }}></label>
                </div>
            </div>
        );
    }
}
export default withTranslation('withTranslation')(SingleSelection);
