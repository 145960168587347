import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'

import Page from '../components/Page';
import Engine from '../components/Engine';

import Button from '../components/ui/Button';
import PopUp from '../components/ui/PopUp';

import deluxe from '../configs/deluxe.js';

let original;

class EnginePage extends Component {

    constructor(props) {
        super(props);
    
        const engine = this.props.engine || deluxe;
        original = JSON.parse(JSON.stringify(engine));

        const initialState = () => {
            const localStorageState = localStorage.getItem(`engine_${engine.id}`);
            if (localStorageState){
                return JSON.parse(localStorageState);
            }
            return {
                key: Math.random().toString(36).substring(7),
                activeSector: 0,
                engine: engine,
                restartPopOver: false
            };
        }

        this.state = initialState()
    }

    reset() {
        localStorage.removeItem(`engine_${this.state.engine.id}`);
        this.setState({
            key: Math.random().toString(36).substring(7),
            activeSector: 0,
            engine: JSON.parse(JSON.stringify(original))
        });
        window.location.reload();
    }

    componentDidUpdate() {
        const engine = this.state.engine;
        localStorage.setItem(`engine_${engine.id}`, JSON.stringify(this.state))
    }

    engineObject = ()  => <Engine 
        key={this.state.key}
        engine={this.state.engine} 
        activeSector={this.state.activeSector}
        setState={(state) => this.setState(state)}
    />

    render() {
        
        const { t } = this.props;
        const { engine, restartPopOver } = this.state;
        return (
            <Page
                {...this.props}
                headerComponents={() => (
                    <>
                        <PopUp
                            isActive={restartPopOver}
                            onCancel={() => {
                                this.setState({ restartPopOver: false})
                            }}
                        >
                            
                        </PopUp>
                        <Button
                            style={{ border: `2px solid ${engine.accentColor}`, background: `${engine.accentColor}` }}
                            hoveredStyle={{ background: `transparent`, border: `2px solid ${engine.accentColor}`, transform: 'none', color: `${engine.accentColor}` }}
                            onClick={() => this.reset()  }
                        >
                            {t("restart")}
                        </Button>
                    </>
                )}
                children={() => this.engineObject()}
            />
        );
    }
}

export default withTranslation("translation")(EnginePage)