import Engine from '../models/Engine';
import Instruction from '../models/Instruction';
import Message from '../models/Message';
import Sector from '../models/Sector';
import Question from '../models/Question';
import Grid from '../models/Grid';

import SectorType from '../models/SectorType';
import InputType from '../models/InputType';
import AnswerType from '../models/AnswerType';

const subjectQuestions = [];
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What would you like your video to be about?',
        'video',
        InputType.circle,
        AnswerType.input,
        'Write your answer in the circle below'
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose 4 words to describe {ANSWER0}',
        '4 words',
        InputType.square,
        AnswerType.input,
        'Type in a word next to each side of the square in any order'
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose 2 words that other people use to describe the location  where a business or video takes place of {ANSWER0}',
        'location',
        InputType.infinity,
        AnswerType.input,
        'Attach a word to each side of the symbol. (tip: this question isn’t asking what you think, but it asks what other people think)'
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose 2 words to describe what other people think about the people involved with the video or who work for {ANSWER0}',
        'people',
        InputType.infinity,
        AnswerType.input,
        'Attach a word to each side of the symbol. (tip: this question isn’t asking what you think, but it asks what other people think)'
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose 2 words that other people use to describe the website or social media presence of {ANSWER0}',
        'website or social media presence',
        InputType.infinity,
        AnswerType.input,
        'Attach a word to each side of the symbol ( tip: this question isn’t asking what you think, but it asks what other people think)'
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Identify 3 golden values or the most important principles of {ANSWER0}',
        '3 golden values',
        InputType.triangle,
        AnswerType.input,
        'Type in a word next to each corner of the triangle (e.g. these words are big themes like love and inspiration. For example, if video is about a national park, you might use words like safety, conservation, respect, etc.)'
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Identify the 3 main levels of employees or type of people involved in {ANSWER0}',
        '3 main levels of employees',
        InputType.triangle,
        AnswerType.input,
        'Type in a word next to each corner of the triangle (e.g. if video is about a restaurant, you might say owner, chef, waiter)'
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Identify the 2 most valuable audiences for a video about {ANSWER0}',
        '2 most valuable audiences',
        InputType.infinity,
        AnswerType.input,
        'Attach a word to each side of the symbol'
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Who is the most recognizable person or the best narrator to speak on behalf of {ANSWER0}',
        'best narrator',
        InputType.circle,
        AnswerType.input,
        'Write a word in the circle below'
    )
);

subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'How would you describe the fashion or the clothes of {ANSWER19}, or what would be the best clothes for {ANSWER19} to wear in an interview about {ANSWER0}',
        'fashion',
        InputType.circle,
        AnswerType.input,
        'Write a word in the circle below'
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What is the most important milestone in the video of {ANSWER0}',
        'milestone',
        InputType.circle,
        AnswerType.input
    )
);
subjectQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'What kind of music do you want people to hear when they watch a video about {ANSWER0}',
        'music',
        InputType.circle,
        AnswerType.input
    )
);

// 12 questions
const subjectQuestionsSector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.steps,
    subjectQuestions
);

const breatherSector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.message,
    new Message(
        Math.random().toString(36).substring(7),
        'Congratulations! You’re 1 step closer to making a video about {ANSWER0}',
        'Take a breather.',
        'Continue'
    )
);

const selectionQuestions = [];
selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite word',
        '',
        InputType.triangle,
        AnswerType.selection,
        'Click on one of the corners of the triangle',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER11}', '{ANSWER12}', '{ANSWER13}']
    )
);
selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Eliminate 1 word that does not belong',
        '',
        InputType.square,
        AnswerType.selection,
        'Click on one of the sides of the square to get rid of a word (tip: if you want to change your selection, then just click on a word you already selected to deselect it.)',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER1}', '{ANSWER2}', '{ANSWER3}', '{ANSWER4}']
    )
);
selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite 3 words',
        '',
        InputType.hexagram,
        AnswerType.selection,
        'Click on 3 corners of the shape to make your decision. (tip: if you want to change your selection, then just click on a word you already selected to deselect it.)',
        '',
        'Previous',
        'Next',
        [undefined, undefined, undefined],
        ['{ANSWER5}', '{ANSWER6}', '{ANSWER7}', '{ANSWER8}', '{ANSWER9}', '{ANSWER10}']
    )
);
selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Now it is time to select 1 favorite word',
        '',
        InputType.infinity,
        AnswerType.selection,
        'Click on either side of the symbol to select a word (tip: if you want to change your selection, then just click on a word you already selected to deselect it.)',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER17}', '{ANSWER18}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite answer',
        '',
        InputType.triangle,
        AnswerType.selection,
        'Click on one of the corners of the triangle (tip: if you want to change your selection, then just click on a word you already selected to deselect it.)',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANTIMATTER13[0]}', '{ANTIMATTER13[1]}', '{ANTIMATTER13[2]}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite answer',
        '',
        InputType.pentagram,
        AnswerType.selection,
        'Click on one of the corners of the star to select a word (tip: if you want to change your selection, then just click on a word you already selected to deselect it.)',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER25}', '{ANSWER26}', '{ANSWER27}', '{ANSWER20}', '{ANSWER22}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite answer',
        '',
        InputType.pentagram,
        AnswerType.selection,
        'Click on one of the corners of the shape to select a word (tip: if you want to change your selection, then just click on a word you already selected to deselect it.)',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER14}', '{ANSWER15}', '{ANSWER16}', '{ANSWER17}', '{ANSWER18}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite 2 words',
        '',
        InputType.square,
        AnswerType.selection,
        'Click on two sides of the square to select your words (tip: if you want to change your selection, then just click on a word you already selected to deselect it.)',
        '',
        'Previous',
        'Next',
        [undefined, undefined],
        ['{ANSWER24}', '{ANTIMATTER14[0]}', '{ANTIMATTER14[1]}', '{ANTIMATTER14[2]}']
    )
);

selectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        "What is the name of a website or a #hashtag that you would add at the end of your video?",
        '',
        InputType.circle,
        AnswerType.input,
        'Write any #hashtag or website in the circle below.'
    )
);

const selectionQuestionsSector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.steps,
    selectionQuestions
);

const halfwaySector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.message,
    new Message(
        Math.random().toString(36).substring(7),
        'Congratulations! Your story about {ANSWER0} is halfway there!',
        'Take another breather. Click next to continue.',
        'Continue'
    )
);

const gridInstructionSector = new Sector(
    Math.random().toString(36).substring(7),
    'instruction',
    'grid instruction',
    SectorType.instruction,
    new Instruction(
        0,
        'Now we’re going to play an intuition game',
        `
            1. The first ideas that come into your mind are the ones that we want. 
            2. There is never a right or a wrong answer. 
            3. Don’t use the same answer twice. 
            4. It’s easier if you pretend that your eyes are a camera and your brain is an image library. 
            5. For example, if the question asks you, “what do you think of when you hear ‘love’ and ‘focus’, you might respond with ‘rose’ or ‘rose in a vase on a table’.
        `
    )
);

const gridSector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.grid,
    new Grid(
        Math.random().toString(36).substring(7),
        'Image grid',
        'Click on any number in the grid below. Once the grid is complete, you will be able to proceed to the final section.',
        [
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER23} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER30} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER31} focus',
                    'focus',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER23} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER30} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER31} people',
                    'people',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER23} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER30} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER31} senses',
                    'senses',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
            [
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER23} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER29} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER30} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
                new Question(
                    Math.random().toString(36).substring(7),
                    'What image do you think of when you hear the words {ANSWER31} setting',
                    'setting',
                    InputType.circle,
                    AnswerType.input,
                    'Write your answer in the circle below, and it can be anything',
                    '',
                    '',
                    'Submit'
                ),
            ],
        ]
    )
);

const gridSelectionQuestions = [];
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for focus',
        'focus',
        InputType.square,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER35}', '{ANSWER36}', '{ANSWER37}', '{ANSWER38}']
    )
);
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for people',
        'people',
        InputType.square,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER39}', '{ANSWER40}', '{ANSWER41}', '{ANSWER42}']
    )
);
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for senses',
        'senses',
        InputType.square,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER43}', '{ANSWER44}', '{ANSWER45}', '{ANSWER46}']
    )
);
gridSelectionQuestions.push(
    new Question(
        Math.random().toString(36).substring(7),
        'Choose your favorite image for setting',
        'setting',
        InputType.square,
        AnswerType.selection,
        'Click on 1 corner of the shape to make your selection',
        '',
        'Previous',
        'Next',
        [undefined],
        ['{ANSWER47}', '{ANSWER48}', '{ANSWER49}', '{ANSWER50}']
    )
);
const gridSelectionQuestionsSector = new Sector(
    Math.random().toString(36).substring(7),
    '',
    '',
    SectorType.steps,
    gridSelectionQuestions
);

const synopsis = `
<p>The main theme of a video about {ANSWER0} is {ANSWER23}.</p>
<p>Your primary audience is {ANSWER28}.</p>
<p>If you want to compose a voiceover for a video about {ANSWER0}, remember that you can comfortably say 125 words in a minute, at most 150 words.</p>
<p>Emphasize these words in a voiceover about {ANSWER0}:  {ANSWER23}, {ANSWER29}, {ANSWER30} and {ANSWER31}.</p>
<p>The first choice for a narrator for a video about {ANSWER0} is {ANSWER19} who needs to speak in a voice that sounds {ANSWER32} and {ANSWER33}, but does NOT sound {ANTIMATTER19[0]} or {ANTIMATTER19[1]}.</p>
<p>The best place for an on camera interview with {ANSWER19} is in a place that feels {ANSWER29}. Chose clothes that are {ANSWER20} for the interview.</p>
<p>Start your video with music that sounds like {ANSWER22} and add images of {ANSWER53}. Or, start with an interview, and then add images of {ANSWER53}.</p>
<p>Bring your story to life with images of {ANSWER51} that feel {ANSWER23} and use images of {ANSWER54} to put your video into context.</p>
<p>Remind the audience about {ANSWER21} and use an image of {ANSWER52} that includes {ANSWER31}.</p>
<p>End your video with a call to action that includes the following website or hashtag: {ANSWER34}</p>
`;

const synopsisSector = new Sector(
    Math.random().toString(36).substring(7),
    'One minute video about {ANSWER0}',
    '',
    SectorType.synopsis,
    synopsis
);

const sectors = [
    subjectQuestionsSector,
    breatherSector,
    selectionQuestionsSector,
    halfwaySector,
    gridInstructionSector,
    gridSector,
    gridSelectionQuestionsSector,
    synopsisSector,
];
const engine = new Engine('medium', 'Medium', sectors);

export default engine;
