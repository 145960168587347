import React, { Component } from 'react';

import Step from '../Step';
import Steps from '../Steps';

export default class StepperSector extends Component {
    state = {
        step: 0,
    };

    render() {
        const { questions, engine, allQuestions, answers, changeQuestion, nextSector } = this.props;
        const { step } = this.state;
        return (
            <main>
                <Steps step={step} steps={questions} onStepChange={(step) => this.setState({ step })} {...this.props} />
                {step < questions.length &&
                    questions.map((question, index) => (
                        <Step
                            key={index}
                            index={index}
                            engine={engine}
                            primaryColor={engine.primaryColor}
                            accentColor={engine.accentColor}
                            questions={allQuestions}
                            question={question}
                            answers={answers}
                            totalCount={questions.length}
                            activeStep={step}
                            onAnswer={(e, i) => {
                                const newQuestion = { ...question };
                                newQuestion.answer[i] = e === '' ? null : e;

                                changeQuestion(newQuestion);
                            }}
                            onNext={(_) => {
                                if (step >= questions.length - 1) {
                                    nextSector();
                                } else {
                                    this.setState({ step: step + 1 });
                                }
                            }}
                            onPrevious={(_) => this.setState({ step: step - 1 })}
                        />
                    ))}
            </main>
        );
    }
}
