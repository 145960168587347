import React, { Component } from 'react';

/**
    The icon that shows the steps 

    @Props activeStep: int -> indicates the current active step
    @Props index: int -> stepIcon index
    @Props onClick: function -> 
 */
export default class StepIcon extends Component {
    render() {
        const { activeStep, index, accentColor, primaryColor, onClick, step } = this.props;
        return (
            <div
                style={{
                    border: `2px solid ${activeStep === index ? accentColor : primaryColor}`,
                    color: activeStep === index ? `${accentColor}` : primaryColor,
                }}
                className={activeStep >= index ? 'stepIcon active' : 'stepIcon'}
                onClick={(e) => {
                    if (activeStep >= index) onClick(e);
                }}
            >
                {step || 0}
            </div>
        );
    }
}
