/**
    Data model for the instruction page
 */
export default class Message {
    id;

    title;
    content;

    nextButtonText;

    constructor(id, title, content, nextButtonText = 'continue') {
        this.id = id;
        this.title = title;
        this.content = content;
    }
}
