/**
    Data model for the instruction page
 */
export default class Instruction {

    id;

    title;
    content;

    nextButtonText
    
    constructor(id, title, content, nextButtonText = "next") {
        this.id = id;
        this.title = title;
        this.content = content;

        this.nextButtonText = nextButtonText;
    }
}