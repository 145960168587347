import React, { Component } from 'react';

import Sector from './Sector';

/**
    The Engine functional component is the overlaying sector & data management layer.
 */
export default class Engine extends Component {
    render() {
        const { key, activeSector, engine, setState } = this.props;

        return (
            <div className="container" key={key}>
                <Sector
                    sector={engine.sectors[activeSector]}
                    engine={engine}
                    changeSector={(newSector) => {
                        const newEngine = { ...engine };
                        const sectorIndex = newEngine.sectors.findIndex((el) => el.id === newSector.id);
                        newEngine.sectors[sectorIndex] = newSector;

                        setState({ engine: newEngine });
                    }}
                    nextSector={() => setState({ activeSector: activeSector + 1 })}
                    previousSector={() => setState({ activeSector: activeSector - 1 })}

                />
            </div>
        );
    }
}
