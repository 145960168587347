import React from 'react';

import ShapeComponent from './ShapeComponent';

export default class Hexagram extends ShapeComponent {
    render() {
        return (
            <div className="hexagram">
                {[0, 1, 2, 3, 4, 5].map((index) => this._renderInput(index))}
                <div className="absolute-center center-shape">
                    <div className="shape hexagram"></div>
                </div>
            </div>
        );
    }
}
