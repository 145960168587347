import React, { Component } from 'react';

export default class PopUp extends Component {
    render() {
        const { children, isActive, onCancel } = this.props;

        return (
            <div className={`popUp${isActive ? ' active' : null}`}>
                <div className="background" onClick={(e) => onCancel && onCancel(e)}></div>
                <div className="children">{children}</div>
            </div>
        );
    }
}
